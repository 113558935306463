export const INIT_SUCCESS = 'INIT_SUCCESS';
export const INIT_FAIL = 'INIT_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const SET_REPORT_TYPE = 'SET_REPORT_TYPE';
export const SET_REPORT_PRIORITIES = 'SET_REPORT_PRIORITIES';
export const SET_SLICE = 'SET_SLICE';
export const SET_REPORT_FILTERS = 'SET_REPORT_FILTERS';
export const SET_REPORT_DATES = 'SET_REPORT_DATES';
export const SET_REPORT_START_DATE = 'SET_REPORT_START_DATE';
export const SET_REPORT_END_DATE = 'SET_REPORT_END_DATE';
export const SET_REPORT_ALGORITHMS = 'SET_REPORT_ALGORITHMS';
export const SET_REPORT_DISPLAY_FINANCIAL_RESULT =
  'SET_REPORT_DISPLAY_FINANCIAL_RESULT';
export const SET_REPORT_FINANCIAL_RESULT_CURRENCY =
  'SET_REPORT_FINANCIAL_RESULT_CURRENCY';
export const SET_REPORT_FINANCIAL_RESULT_ABS =
  'SET_REPORT_FINANCIAL_RESULT_ABS';
export const TOGGLE_REPORT_EMPTY_COLUMNS_FILTERED =
  'TOGGLE_REPORT_EMPTY_COLUMNS_FILTERED';
export const SET_RULE_GROUPS_BY_WORKSPACE = 'SET_RULE_GROUPS_BY_WORKSPACE';
export const SET_SIDE_MENU_CONFIG = 'SET_SIDE_MENU_CONFIG';
export const SET_SIDE_MENU_SCROLL = 'SET_SIDE_MENU_SCROLL';
export const SET_CHART_AA_RULE = 'SET_CHART_AA_RULE';
export const SET_CHART_CMP_TICKER = 'SET_CHART_CMP_TICKER';
export const SET_CHART_CMP_DATE_FROM = 'SET_CHART_CMP_DATE_FROM';
export const SET_CHART_CMP_DATE_TO = 'SET_CHART_CMP_DATE_TO';
export const SET_CHART_CMP_CLIENT = 'SET_CHART_CMP_CLIENT';
export const SET_CHART_CMP_RULE = 'SET_CHART_CMP_RULE';
export const SET_CHART_CMP_DATA = 'SET_CHART_CMP_DATA';
export const SET_STATUS_IS_RUNNING = 'SET_STATUS_IS_RUNNING';
export const SET_APP_MODE_AND_WORKSPACE = 'SET_APP_MODE_AND_WORKSPACE';
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const SET_ISLOADED_ALL = 'SET_ISLOADED_ALL';
export const SET_ISLOADED_DASHBOARD = 'SET_ISLOADED_DASHBOARD';
export const SET_ISLOADED_FLAGGED = 'SET_ISLOADED_FLAGGED';
export const SET_ISLOADED_KRI = 'SET_ISLOADED_KRI';
export const SET_ISLOADED_CHART_AA = 'SET_ISLOADED_CHART_AA';
export const SET_STATUS_READ_IDS = 'SET_STATUS_READ_IDS';
export const SET_STATUS_NEW_IDS = 'SET_STATUS_NEW_IDS';
export const TOGGLE_STATUS_AUTO_UPDATE = 'TOGGLE_STATUS_AUTO_UPDATE';
export const SORT_RULES_BY_RULE_NAME = 'SORT_RULES_BY_RULE_NAME';
export const SORT_RULES_BY_RULE_CODE = 'SORT_RULES_BY_RULE_CODE';
export const SET_REPORT_DATE = 'SET_REPORT_DATE';
export const SET_RULES_DETAILS = 'SET_RULES_DETAILS';
export const SET_RULES_USER = 'SET_RULES_USER';
export const SET_SELECTED_RULE_GROUP = 'SET_SELECTED_RULE_GROUP';
export const SET_OVERLAP_DEALS_DATE = 'SET_OVERLAP_DEALS_DATE';
export const SET_OVERLAP_ORDERS_DATE = 'SET_OVERLAP_ORDERS_DATE';
export const SET_OVERLAP_CLIENTS_DATE = 'SET_OVERLAP_CLIENTS_DATE';
export const SET_UNPROCESSED_INC_DATE = 'SET_UNPROCESSED_INC_DATE';
export const SET_TRADING_AUDIT_DATA = 'SET_TRADING_AUDIT_DATA';
export const SET_DEAL_ORDER_AUDIT_DATA = 'SET_DEAL_ORDER_AUDIT_DATA';
export const SET_BEHAVIORAL_AUDIT_DATA = 'SET_BEHAVIORAL_AUDIT_DATA';
export const SET_AUDIT_OPTIONS = 'SET_AUDIT_OPTIONS';
export const RESTORE_AUDIT_INITIAL_OPTIONS = 'RESTORE_AUDIT_INITIAL_OPTIONS';
export const INITIALIZE_AUDIT_OPTIONS = 'INITIALIZE_AUDIT_OPTIONS';
export const SET_INCIDENT_MANAGEMENT_DATA = 'SET_INCIDENT_MANAGEMENT_DATA';
export const SET_INCIDENT_NEWS_DATA = 'SET_INCIDENT_NEWS_DATA';
export const SET_INCIDENT_CHART_DATA = 'SET_INCIDENT_CHART_DATA';
export const RESTORE_INCIDENT_INITIAL_STATE = 'RESTORE_INCIDENT_INITIAL_STATE';
export const SET_CLIENT_CONNECTION_STAT_DATA =
  'SET_CLIENT_CONNECTION_STAT_DATA';
export const RESTORE_CLIENT_INITIAL_STATE = 'RESTORE_CLIENT_INITIAL_STATE';
export const SET_CLIENT_BEHAVIORAL_AUDIT_DATA =
  'SET_CLIENT_BEHAVIORAL_AUDIT_DATA';
export const INITIALIZE_CLIENT_AFFILIATION_FILTERS =
  'INITIALIZE_CLIENT_AFFILIATION_FILTERS';
export const SET_CLIENT_AFFILIATION_FILTERS = 'SET_CLIENT_AFFILIATION_FILTERS';
export const SET_RISK_MODEL_SETTINGS = 'SET_RISK_MODEL_SETTINGS';
export const SET_RISK_MODEL_LOADED = 'SET_RISK_MODEL_LOADED';
