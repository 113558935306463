const apiPrefix = '/api/';

export const LOGIN_URL = '/login';
export const LOGOUT_URL = '/logout';

/* about  */
/*****************************************************************/
const aboutPrefix = apiPrefix + '/about';
export const GET_APP_VERSION = aboutPrefix + '/version';

/* refs (Reference information)  */
/*****************************************************************/
const refsPrefix = apiPrefix + 'refs/';
export const GET_SUMMARY_URL = refsPrefix + 'summary';
export const FIX_ISSUES_URL = refsPrefix + 'fixIssues';
export const GET_ASSETS = refsPrefix + 'dicts/assets';
export const GET_ASSET_CLASSES = refsPrefix + 'dicts/assetClasses';
export const GET_BUSINESSLINES = refsPrefix + 'dicts/businessLines';
export const UPDATE_REF_COUNTERPARTY_URL =
  refsPrefix + 'update-ref-counterparty';
export const UPDATE_REF_INSIDERS_URL = refsPrefix + 'update-ref-insiders';
export const UPDATE_REF_ISSUES_URL = refsPrefix + 'update-ref-issues';
export const UPDATE_REF_STAFF_URL = refsPrefix + 'update-ref-staff';
export const GET_REF_HISTORY = refsPrefix + 'find-history-{refName}';
export const UPDATE_REF_INDEXES_URL = refsPrefix + 'update-ref-index';
export const UPDATE_REF_FIXING_INTEREST_URL =
  refsPrefix + 'update-ref_fixing_interest';
export const UPDATE_REF_MOEX_FX_URL = refsPrefix + 'update-ref_moex_fx';
export const UPLOAD_REF_DOCUMENT = refsPrefix + 'create-or-update';
export const DELETE_REF_DOCUMENT = refsPrefix + 'delete';
export const UPDATE_CLIENT_DESCRIPTION =
  refsPrefix + 'fill-client-description-by-client';

/* algorithms  */
/*****************************************************************/
const algoPrefix = apiPrefix + 'algorithms/';

const scheduleAlgoPrefix = algoPrefix + 'schedule/';
export const ALGO_FINE_TUNING_SCHEDULE_URL = scheduleAlgoPrefix + 'fineTuning';
export const ALGO_ML_FINE_TUNING_SCHEDULE_URL =
  scheduleAlgoPrefix + 'ml/fineTuning';
export const RESET_FINE_TUNING_SCHEDULE_URL =
  scheduleAlgoPrefix + 'resetFineTuning';
export const GET_FINE_TUNINGS_SCHEDULE_URL =
  scheduleAlgoPrefix + 'download-fineTunings';
export const SET_FINE_TUNINGS_SCHEDULE_URL =
  scheduleAlgoPrefix + 'upload-fineTunings';
export const UPDATE_ALGOS_ACTIVATED_SCHEDULE_URL =
  scheduleAlgoPrefix + 'update-activated';
export const SET_GLOBAL_TICKERS_SCHEDULE_URL =
  scheduleAlgoPrefix + 'save-global-tickers';
export const SET_GLOBAL_CLIENTS_SCHEDULE_URL =
  scheduleAlgoPrefix + 'save-global-clients';
export const SET_GLOBAL_MARKET_MAKER_SCHEDULE_URL =
  scheduleAlgoPrefix + 'save-global-is-market-maker';
export const RUN_SCAN_ALGO = scheduleAlgoPrefix + 'scan-per-range';
export const STOP_SCAN_ALGO = scheduleAlgoPrefix + 'stop-scan-per-range';

const analyticAlgoPrefix = algoPrefix + 'analytic/';
export const ALGO_FINE_TUNING_ANALYTIC_URL = analyticAlgoPrefix + 'fineTuning';
export const ALGO_ML_FINE_TUNING_ANALYTIC_URL =
  analyticAlgoPrefix + 'ml/fineTuning';
export const RESET_FINE_TUNING_ANALYTIC_URL =
  analyticAlgoPrefix + 'resetFineTuning';
export const GET_FINE_TUNINGS_ANALYTIC_URL =
  analyticAlgoPrefix + 'download-fineTunings';
export const SET_FINE_TUNINGS_ANALYTIC_URL =
  analyticAlgoPrefix + 'upload-fineTunings';
export const RUN_ALGO_ANALYTIC_URL = analyticAlgoPrefix + 'run';
export const RUN_ALL_SELECTED_ALGOS_ANALYTIC_URL =
  analyticAlgoPrefix + 'run-all-selected';
export const STOP_RUNNING_ALGO_ANALYTIC_URL = analyticAlgoPrefix + 'stop';
export const UPDATE_ALGOS_ACTIVATED_ANALYTIC_URL =
  analyticAlgoPrefix + 'update-activated';
export const UPDATE_FINE_TUNINGS_OPERATORS_URL =
  SET_FINE_TUNINGS_ANALYTIC_URL + '/operator';
export const SET_GLOBAL_TICKERS_ANALYTIC_URL =
  analyticAlgoPrefix + 'save-global-tickers';
export const SET_GLOBAL_CLIENTS_ANALYTIC_URL =
  analyticAlgoPrefix + 'save-global-clients';
export const SET_GLOBAL_MARKET_MAKER_ANALYTIC_URL =
  analyticAlgoPrefix + 'save-global-is-market-maker';
export const GET_RULES_EDIT_USERS =
  analyticAlgoPrefix + 'get-all-users/investigation_list_of_rules_view';

const signalAlgoPrefix = algoPrefix + 'signal/';
export const ALGO_FINE_TUNING_SIGNAL_URL = signalAlgoPrefix + 'fineTuning';
export const RESET_FINE_TUNING_SIGNAL_URL =
  signalAlgoPrefix + 'resetFineTuning';
export const RUN_ALGO_SIGNAL_URL = signalAlgoPrefix + 'run';

/* algorithm templates  */
/*****************************************************************/
const templatePrefix = apiPrefix + 'template/';
export const CREATE_ALGO_TEMPLATE_URL = templatePrefix + 'create';
export const DELETE_ALGO_TEMPLATE_URL = templatePrefix + 'delete';
export const RECOVER_ALGO_TEMPLATE_URL = templatePrefix + 'recover';

/* incidents (incidents information)  */
/*****************************************************************/

export const incidentPrefix = apiPrefix + 'incident-management/';
export const GET_NEWS_URL = incidentPrefix + 'find-news';
export const UPDATE_INCIDENT_MANAGEMENT =
  incidentPrefix + 'update-assign-incident';
export const FIND_INCIDENT_MANAGMENT_HISTORY =
  incidentPrefix + 'find-history-incidentManagement';

/* incidents import/export */
/*****************************************************************/
const archivePrefix = apiPrefix + 'archive/';
export const ARCHIVE_INCIDENTS_URL = archivePrefix + 'incidents';
export const IMPORT_INCIDENTS_ARCHIVE_URL = archivePrefix + 'import/incidents';

/* dashboards filters  */
/*****************************************************************/
const dashboardPrefix = apiPrefix + 'dashboard/';
const commonPrefix = dashboardPrefix + 'common/';

export const SAVE_FILTERS_URL =
  commonPrefix + 'savefilters-and-update-key-and-graph-indicator';
export const GET_INCIDENT_REPORT_URL = commonPrefix + 'createIncidentReport';

/* dashboards data  */
/*****************************************************************/
const scheduleDashboardPrefix = dashboardPrefix + 'schedule/';
export const GET_BY_RULE_GROUP_REPORT_SCHEDULE_URL =
  scheduleDashboardPrefix + 'by-ruleGroup';
export const GET_BY_RULE_REPORT_SCHEDULE_URL =
  scheduleDashboardPrefix + 'by-rule';
export const GET_BY_PERIOD_REPORT_SCHEDULE_URL =
  scheduleDashboardPrefix + 'by-period';
export const GET_BY_MONTH_REPORT_SCHEDULE_URL =
  scheduleDashboardPrefix + 'by-month';
export const GET_FLAGGED_REPORT_SCHEDULE_URL =
  scheduleDashboardPrefix + 'by-flagged';
export const GET_KRI_REPORT_SCHEDULE_URL = scheduleDashboardPrefix + 'by-kri';
export const GET_GRAPH_REPORT_SCHEDULE_URL =
  scheduleDashboardPrefix + 'by-graph';
export const GET_PROCESSED_DATA_URL =
  scheduleDashboardPrefix + 'processed-data';
export const GET_PROCESSING_RESULT_URL =
  scheduleDashboardPrefix + 'processing-result';

const analyticDashboardPrefix = dashboardPrefix + 'analytic/';
export const GET_BY_RULE_GROUP_REPORT_ANALYTIC_URL =
  analyticDashboardPrefix + 'by-ruleGroup';
export const GET_BY_RULE_REPORT_ANALYTIC_URL =
  analyticDashboardPrefix + 'by-rule';
export const GET_BY_PERIOD_REPORT_ANALYTIC_URL =
  analyticDashboardPrefix + 'by-period';
export const GET_BY_MONTH_REPORT_ANALYTIC_URL =
  analyticDashboardPrefix + 'by-month';
export const GET_FLAGGED_REPORT_ANALYTIC_URL =
  analyticDashboardPrefix + 'by-flagged';
export const GET_KRI_REPORT_ANALYTIC_URL = analyticDashboardPrefix + 'by-kri';
export const GET_GRAPH_REPORT_ANALYTIC_URL =
  analyticDashboardPrefix + 'by-graph';

/* case management  */
/*****************************************************************/
const casePrefix = apiPrefix + 'cases/';
export const CREATE_CASE_URL = casePrefix + 'create';
export const UPDATE_CASE_URL = casePrefix + 'update';
export const DELETE_CASE_URL = casePrefix + 'delete';
export const CREATE_CASE_ACTIVITY_URL = casePrefix + 'createActivity';
export const UPDATE_CASE_ACTIVITY_URL = casePrefix + 'updateActivity';
export const DELETE_CASE_ACTIVITY_URL = casePrefix + 'deleteActivity';
export const ADD_INCIDENTS_TO_CASE_URL = casePrefix + 'addIncidents';
export const REMOVE_INCIDENTS_FROM_CASE_URL = casePrefix + 'removeIncidents';
export const GET_CASE_REPORT_URL = casePrefix + 'createCaseReport';
export const GET_CASE_INCIDENT_REPORT_URL = casePrefix + 'createIncidentReport';
export const GET_CASE_FILE_URL = casePrefix + 'files';
export const GET_CASE_USERS_URL = casePrefix + 'users';
export const GET_CASE_DOC_REPORT_URL = casePrefix + 'createDocCaseReport/:id';
export const GET_CASE_REGULATOR_REPORT_URL = casePrefix + 'create-cb-report';
export const CREATE_CASE_REGULATOR_REPORT =
  casePrefix + 'create_case_regulator_report';

/* upload data  */
/*****************************************************************/
const uploadDataPrefix = apiPrefix + 'upload/';
export const UPLOAD_ORDERS_SEM_URL = uploadDataPrefix + 'order-sem02';
export const UPLOAD_DEALS_SEM_URL = uploadDataPrefix + 'base-sem03';
export const UPLOAD_MKT_SEM_URL = uploadDataPrefix + 'mkt-sem21';
export const UPLOAD_BASE_CSV_URL = uploadDataPrefix + 'upload-base-import';
const uploadRefsPrefix = refsPrefix + 'csv/upload/';
export const UPLOAD_REF_COUNTERPARTY_CSV_URL =
  uploadRefsPrefix + 'ref_counterparty';
export const UPLOAD_REF_ISSUES_CSV_URL = uploadRefsPrefix + 'ref_issues';
export const UPLOAD_REF_STAFF_CSV_URL = uploadRefsPrefix + 'ref_staff';
export const UPLOAD_REF_BOARDS_CSV_URL = uploadRefsPrefix + 'ref_boards';
export const UPLOAD_MOEX_REPORT = uploadDataPrefix + 'import-moex-report';
export const UPLOAD_MOEX_REPORT_RUN_ALGO =
  uploadDataPrefix + 'import-moex-report-run-algo';

const OTCPrefix = apiPrefix + 'OTC/';
export const UPLOAD_OTC_DEALS = OTCPrefix + 'upload-deals';
export const UPLOAD_OTC_OPTIONS = OTCPrefix + 'upload-options';
export const UPLOAD_OTC_REF_ISSUES = OTCPrefix + 'upload-ref-issues';
export const UPLOAD_OTC_REF_COUNTERPARTY =
  OTCPrefix + 'upload-ref-counterparty';
export const UPLOAD_OTC_REF_RATES = OTCPrefix + 'upload-ref-rates';
export const UPLOAD_OTC_REF_STAFF = OTCPrefix + 'upload-ref-staff';
export const UPLOAD_OTC_MKT_DAYS = OTCPrefix + 'upload-mkt-days';

export const UPLOAD_REF_CONNECTIONS_QUIK_UID =
  refsPrefix + 'xlsx/upload/quik_uid';
export const UPLOAD_REF_INDEX = refsPrefix + 'upload-index-index-table';

const uploadDataLitePrefix = apiPrefix + 'loader/file/';
export const UPLOAD_LITE_REF_ISSUES_RATES_URL =
  uploadDataLitePrefix + 'ref-issues-rates';
export const UPLOAD_LITE_REF_ISSUES_EXPORT_URL =
  uploadDataLitePrefix + 'ref-issues-export';
export const UPLOAD_LITE_ORDERBOOK_URL = uploadDataLitePrefix + 'orderbook';
export const UPLOAD_LITE_INSIDERS_CSV_URL =
  uploadDataLitePrefix + 'insiders-csv';
export const UPLOAD_LITE_OPTIONS_MKT_DAYS_URL =
  uploadDataLitePrefix + 'options-mkt-days';
export const UPLOAD_LITE_FUTURES_MKT_DAYS_URL =
  uploadDataLitePrefix + 'futures-mkt-days';
export const UPLOAD_LITE_CUX_FILES_22_XML_URL =
  uploadDataLitePrefix + 'cux-files-22-xml';
export const UPLOAD_LITE_SPB_FILES_03_XML_URL =
  uploadDataLitePrefix + 'spb-files-03-xml';
export const UPLOAD_LITE_QUIK_DEALS_URL = uploadDataLitePrefix + 'quik-deals';
export const UPLOAD_LITE_CUX_FILES_23_XML_URL =
  uploadDataLitePrefix + 'cux-files-23-xml';
export const UPLOAD_LITE_SEM_FILES_03_XML_URL =
  uploadDataLitePrefix + 'sem-files-03-xml';
export const UPLOAD_LITE_QUIK_ORDERS_URL = uploadDataLitePrefix + 'quik-orders';
export const UPLOAD_LITE_SEM_FILES_02_XML_URL =
  uploadDataLitePrefix + 'sem-files-02-xml';
export const UPLOAD_LITE_SPB_FILES_02_TXT_URL =
  uploadDataLitePrefix + 'spb-files-02-txt';
export const UPLOAD_LITE_SEM_FILES_02_TXT_URL =
  uploadDataLitePrefix + 'sem-files-02-txt';
export const UPLOAD_LITE_SPB_FILES_21_XML_URL =
  uploadDataLitePrefix + 'spb-files-21-xml';
export const UPLOAD_LITE_MKT_DAY = uploadDataLitePrefix + 'mkt-day';
export const UPLOAD_LITE_NEWS_URL = uploadDataLitePrefix + 'news';
export const UPLOAD_LITE_DEALS_OTC_SPOT_CSV_URL =
  uploadDataLitePrefix + 'deals-otc-spot-csv';
export const UPLOAD_LITE_DEALS_OTC_OPTION_CSV_URL =
  uploadDataLitePrefix + 'deals-otc-option-csv';
export const UPLOAD_LITE_FUTURES_HOLDINGS_URL =
  uploadDataLitePrefix + 'futures-holdings';
export const UPLOAD_LITE_FUTURES_LIMITS_URL =
  uploadDataLitePrefix + 'futures-limits';
export const UPLOAD_LITE_FPOS_URL = uploadDataLitePrefix + 'fpos';
export const UPLOAD_LITE_OPOS_URL = uploadDataLitePrefix + 'opos';
export const UPLOAD_LITE_DEPO_LIMITS_URL = uploadDataLitePrefix + 'depo-limits';
export const UPLOAD_LITE_MONEY_LIMITS_URL =
  uploadDataLitePrefix + 'money-limits';
export const UPLOAD_LITE_REF_INDEX_URL = uploadDataLitePrefix + 'ref-index';
export const UPLOAD_LITE_REF_STAFF_URL = uploadDataLitePrefix + 'ref-staff';
export const UPLOAD_LITE_DAYO07_URL = uploadDataLitePrefix + 'dayo07';
export const UPLOAD_LITE_O07_URL = uploadDataLitePrefix + 'o07';
export const UPLOAD_LITE_FUTURES_DEALS_URL =
  uploadDataLitePrefix + 'futures-deals';
export const UPLOAD_LITE_OPTIONS_DEALS_URL =
  uploadDataLitePrefix + 'options-deals';

/* tasks  */
/*****************************************************************/
export const GET_TASKS_URL = apiPrefix + 'general/tasks';
export const GET_TASK_HISTORY_BY_ID = apiPrefix + 'general/tasks/history/id';

/* admin functionality  */
/*****************************************************************/
const adminPrefix = apiPrefix + 'admin/';
export const GET_ADMIN_USERS_URL = adminPrefix + 'users/full';
export const GET_USERS_CSV_URL = GET_ADMIN_USERS_URL + '/csv';
export const CREATE_USER_URL = adminPrefix + 'user/create';
export const EDIT_FULL_USER_URL = adminPrefix + 'user/edit/fullUser';
export const DISABLE_USER_URL = adminPrefix + 'user/disable';
export const ENABLE_USER_URL = adminPrefix + 'user/enable';
export const RESET_USERS_DEFAULT_SETTINGS_URL =
  adminPrefix + 'user/reCreateDefaultSetting';
export const GET_ADMIN_PROPERTIES = adminPrefix + 'properties';
export const GET_MAINTENANCE_URL = adminPrefix + 'maintenance/state';
export const STOP_MAINTENANCE_URL = adminPrefix + 'maintenance/stop';
export const START_MAINTENANCE_URL = adminPrefix + 'maintenance/start';
export const GET_TAFS_ROLES_URL = adminPrefix + 'roles/';
export const GET_ROLE_PERMISSIONS_URL = GET_TAFS_ROLES_URL + ':roleName';
export const UPDATE_MODULES_LOGGING = adminPrefix + 'audit';
export const USER_AUDIT_BY_RULE = adminPrefix + 'audit-by-rule-admin';

const userEditPrefix = adminPrefix + 'user/edit/';
export const EDIT_USER_REPORT_URL = userEditPrefix + 'isSendingReport';
export const EDIT_USER_REPORT_FILTER_URL =
  userEditPrefix + 'isSendingReportFilter';
export const EDIT_USER_REPORT_CONNECTION_URL =
  userEditPrefix + 'isSendingReportStatConnection';
export const EDIT_USER_REPORT_AFFILIATED_URL =
  userEditPrefix + 'isSendingReportAffiliated';

/* business admin functionality  */
/*****************************************************************/
export const GET_BUSINESS_SETTINGS_URL = apiPrefix + 'business-settings';
export const UPLOAD_REF_WORKING_DAY =
  apiPrefix + 'business-settings/csv/upload/ref_working_day';
export const FORCE_UPDATE_ALGO_RUN = scheduleAlgoPrefix + 'run-all-active';

export const DELETE_ALL_DATA_URL = refsPrefix + 'delete-all-data';
export const DELETE_DATA_INCIDENTS_URL = refsPrefix + 'delete-all-incidents';
export const DELETE_DATA_ISSUES_URL = refsPrefix + 'delete-ref-issues';
export const DELETE_DATA_COUNTERPARTY_URL =
  refsPrefix + 'delete-ref-counterparty';
export const DELETE_DATA_INSIDERS_URL = refsPrefix + 'delete-ref-insiders';
export const DELETE_DATA_STAFF_URL = refsPrefix + 'delete-ref-staff';
export const DELETE_DATA_MKT_URL = refsPrefix + 'delete-mkt';
export const DELETE_DATA_MKT_DAYS_URL = refsPrefix + 'delete-mkt-days';
export const DELETE_DATA_POSITION_URL = refsPrefix + 'delete-ref-position';
export const DELETE_DATA_INDEXES_URL = refsPrefix + 'delete-ref-index';
export const DELETE_DATA_FIXING_INTEREST_URL =
  refsPrefix + 'delete-ref-fixing-interest';

/* developer functionality  */
/*****************************************************************/
const devPrefix = apiPrefix + 'developer/';
export const RESET_FILTERS_TEMPLATE_URL = devPrefix + 'save-global-values';
export const DELETE_DEV_INCIDENT_BY_ID = devPrefix + 'delete-incident-by-id';
export const DELETE_DEV_DEALS_ORDERS_INCIDENTS =
  devPrefix + 'delete-orders-deals-incidents';
export const DELETE_DEV_NEWS = devPrefix + 'delete-news';
export const DELETE_DEV_MKT = devPrefix + 'delete-mkt';
export const DELETE_DEV_MKT_DAYS = devPrefix + 'delete-mkt-days';
export const DELETE_DEV_INCIDENTS_BY_RULEGROUP =
  devPrefix + 'delete-incident-by-rule-group';
export const LOAD_DEV_DEALS_IMPORT = devPrefix + 'load-deals-import';
export const LOAD_DEV_ORDERS_IMPORT = devPrefix + 'load-orders-import';
export const UPDATE_RELATED_DEALS_ORDERS =
  devPrefix + 'update-related-deals-orders';
export const REGENERATE_INCIDENTS_BY_DATE =
  devPrefix + 'incidentsRegenerateByDate';
export const UPDATE_INCIDENT_DESCRIPTION =
  devPrefix + 'update-incident-description';

const moexPrefix = apiPrefix + 'apimoex/';
export const LOAD_DEV_REF_ISSUES_BY_TICKERS =
  moexPrefix + 'historical/refissues-by-tickers';
export const LOAD_DEV_MKT_BY_TICKERS = moexPrefix + 'historical/mkt-by-tickers';
export const LOAD_DEV_MKT_DAYS_BY_TICKERS =
  moexPrefix + 'historical/mkt-days-by-tickers';
export const RELOAD_DEV_REFRATES_SECURITIES =
  moexPrefix + 'reload-refrates-securities';
export const RELOAD_DEV_MKT_MINUTES =
  moexPrefix + 'historical/reloadmkt-minutes';
export const RELOAD_DEV_MKT_DAYS = moexPrefix + 'historical/reloadmkt-days';
export const RELOAD_DEV_REFISSUES = moexPrefix + 'historical/refissues';
export const RELOAD_DEV_CASH_REFISSUES =
  moexPrefix + 'historical/cash-refissues';
export const LOAD_DEV_REFISSUES_BY_REFISSUES_FROM_MOEX =
  moexPrefix + 'historical/refissues-by-refissues';

/* sandbox  */
/*****************************************************************/
const sandboxPrefix = apiPrefix + 'sandbox/';
export const SANDBOX = sandboxPrefix;
export const RUN_CUSTOM_ALGORITHM = sandboxPrefix + 'run/';
export const VERIFY_CUSTOM_ALGORITHM = sandboxPrefix + 'verification/';
export const STOP_VERIFY_CUSTOM_ALGORITHM =
  sandboxPrefix + 'stop-verification/';
export const DRAFT_CUSTOM_RULE = sandboxPrefix + 'draft/';

/* watch lists */
/*****************************************************************/

export const WATCH_LIST_URL = apiPrefix + 'watch-list';
export const CREATE_WATCH_LIST = WATCH_LIST_URL + '/create';
export const UPDATE_WATCH_LIST = WATCH_LIST_URL + '/update-watch-list';
export const ADD_TO_WATCH_LIST = WATCH_LIST_URL + '/add-client-watch-list';
export const GET_WATCHLIST_CHART_DATA_URL = WATCH_LIST_URL + '/diagram';

/* client card */
/*****************************************************************/

const clientActivityPrefix = apiPrefix + 'client_card/';
export const GET_CLIENT_ACTIVITY_URL = clientActivityPrefix + 'date_statistics';
export const GET_CLIENT_ACTIVITY_WITH_TICKER_URL =
  clientActivityPrefix + 'ticker_date_statistics';
export const UPDATE_CLIENT_CONCLUSION =
  clientActivityPrefix + 'save/conclusion';
export const DELETE_CLIENT_CONCLUSION =
  clientActivityPrefix + `delete/conclusion/:id`;

const connectionPrefix = apiPrefix + 'connection/';
export const GET_CLIENT_CONNECTION_STATS = connectionPrefix + 'statistic';
export const GET_CLIENT_CONNECTION_STATS_PER_DATES =
  connectionPrefix + 'statistic_dates';
export const GET_CLIENT_CONNECTION_STATS_ENUM =
  connectionPrefix + 'statistic_enum';

export const DOWNLOAD_CONNECTIONS_STATS_REPORT =
  connectionPrefix + 'xlsx/download/watch_list';

const affiliationPrefix = apiPrefix + 'affiliated/';
export const DOWNLOAD_AFFILIATION_REPORT =
  affiliationPrefix + 'xlsx/download/watch_list';
export const GET_AFIILIATION_CHAINS =
  affiliationPrefix + 'affiliated-incident-pairs-tree';
export const UPDATE_AFFILIATION_FIELD_URL =
  affiliationPrefix + 'update-custom-field';
export const GET_RELATED_CLIENTS_URL =
  affiliationPrefix + 'getting-related-clients';

/* download gqtable in xls */
/*****************************************************************/

export const GENERATE_XLS = apiPrefix + 'templates/generate-without-template';

/* machine learning */
/*****************************************************************/

export const GET_OPTIMIZATION_TASK_BY_RULECODE_URL =
  GET_TASKS_URL + '?taskName=FineTuningOptimizationTask-<ruleCode>';

const mlPrefix = apiPrefix + 'ml/';
export const RUN_FINE_TUNING_OPTIMIZATION_URL =
  mlPrefix + 'run-fine-tuning-optimization';
export const GET_FINE_TUNING_VALUE_FROM_PERCENTILE_URL =
  mlPrefix + 'calc-slider-percentile';
export const GET_FINE_TUNING_PERCENTILE_FROM_VALUE_URL =
  mlPrefix + 'calc-manual-percentile';
export const GET_ML_CHART_UNFLAGGED_DATA_URL = mlPrefix + 'calc-unflagged-data';
export const GET_ML_CHART_FLAGGED_DATA_URL = mlPrefix + 'calc-flagged-data';
export const GET_ML_CHART_CALC_DATA_URL = mlPrefix + 'calc-graph-data';
export const GET_ML_PIVOT = mlPrefix + 'pivot';
export const POST_ML_SETTINGS_FILE_URL = mlPrefix + 'upload-settings';

export const RUN_INCIDENT_RANKING =
  apiPrefix + 'incident-ranking/rank-algo-incidents';

/* signals */
/*****************************************************************/
const signalPrefix = apiPrefix + 'signal/';
export const RECALCULATE_SIGNALS = signalPrefix + 'recalculate-signals';

/* reports */
/*****************************************************************/

export const reportPrefix = apiPrefix + 'reports/';
export const CREATE_OVERLAP_REPORT = reportPrefix + 'create/overlap-report';
export const DOWNLOAD_OVERLAP_REPORT = reportPrefix + 'download/any-report';
export const GET_OVERLAP_INCIDENTS = reportPrefix + 'search-overlap-incident';
export const GET_UNCALCULATED_INCIDENTS_REPORT =
  reportPrefix + 'ml-visual-data';
export const SAVE_UNCALCULATED_INCIDENTS_REPORT =
  reportPrefix + 'save-ml-report';
export const DOWNLOAD_UNCALCULATED_INCIDENTS_REPORT =
  reportPrefix + 'download/ml-report';
export const GET_AUDIT_DEALS_REPORT =
  reportPrefix + 'audit-click-visual-data-deals';
export const GET_AUDIT_ORDERS_REPORT =
  reportPrefix + 'audit-click-visual-data-orders';
export const GET_AUDIT_INCIDENTS_REPORT =
  reportPrefix + 'audit-click-visual-data-incidents';
export const GET_AUDIT_SIGNALS_REPORT =
  reportPrefix + 'audit-click-visual-data-signals';
export const GET_AUDIT_DEALS_VOLUME =
  reportPrefix + 'audit-net-turnover-deals-by-client';
export const DOWNLOAD_AUDIT_DEALS_REPORT =
  reportPrefix + 'download/audit-click-visual-data-deals';
export const DOWNLOAD_AUDIT_ORDERS_REPORT =
  reportPrefix + 'download/audit-click-visual-data-orders';
export const DOWNLOAD_AUDIT_DEALS_ORDERS_REPORT =
  reportPrefix + 'download/audit-click-visual-deals-and-orders-data';
export const UPDATE_OVERLAP_REPORT_STATUS_URL =
  reportPrefix + 'update-main-overlap-report';
export const UPDATE_OVERLAP_REPORT_SUB_STATUS_URL =
  reportPrefix + 'update-subject-overlap-report';
export const DOWNLOAD_REF_DOCUMENT = reportPrefix + 'files/:id';
export const SET_REF_ROW_RULES = reportPrefix + 'enrich-colors';
export const CREATE_CLIENT_REPORT_URL = reportPrefix + 'download/client-report';

/* TAFS light reports */
/*****************************************************************/

const lightPrefix = apiPrefix + 'light/';
export const CREATE_INCIDENT_REPORT_URL = lightPrefix + 'createIncidentReport';
export const CREATE_CASES_REPORT_URL = lightPrefix + 'createCaseReport';
export const CREATE_CASE_REPORT_URL = lightPrefix + 'createDocCaseReport/:id';
export const CREATE_INC_JUDGMENT_REPORT_URL =
  lightPrefix + 'createWellFoundedJudgmentReport';
export const CREATE_AUDIT_TRADE_ACTIVITY_REPORT_URL =
  lightPrefix + 'createClientTradeActivityReport';
export const CREATE_AUDIT_DEAL_ORDER_REPORT_URL =
  lightPrefix + 'createAuditSuspiciousDealOrOrderReport';
export const CREATE_AUDIT_BEHAVIORAL_REPORT_URL =
  lightPrefix + 'createClientBehavioralAnalysisReport';
export const DOWNLOAD_HISTORY_REPORT_URL =
  lightPrefix + 'download/history/report';

/* historical_user_request */
/*****************************************************************/
const historicalRequestPrefix = apiPrefix + 'historical_user_request/';
export const ADD_USER_FILE_URL = historicalRequestPrefix + 'add_user_file';
export const ADD_INPUT_FILE_URL = historicalRequestPrefix + 'add_input_file';
export const DELETE_USER_FILE_URL =
  historicalRequestPrefix + 'remove_user_file';
export const DELETE_INPUT_FILE_URL =
  historicalRequestPrefix + 'remove_input_file';

/* risk-model */
/*****************************************************************/
const riskModelPrefix = apiPrefix + 'matrix/';
export const SET_RISK_MODEL_TUNINGS_URL = riskModelPrefix + 'fineTuning';
export const GET_RISK_MATRIX_CHART_ALL_URL =
  riskModelPrefix + 'visual-matrix-all';
export const GET_RISK_MATRIX_CHART_ZOOM_URL =
  riskModelPrefix + 'visual-matrix-zoom';
