import {
  INITIALIZE_CLIENT_AFFILIATION_FILTERS,
  SET_CLIENT_AFFILIATION_FILTERS,
} from '@tafs/constants/actions';

const initialState = {
  affiliationFilters: {
    affiliatedGeneralInfo: false,
    affiliatedByConnections: false,
    affiliatedByTrueIncidents: false,
    affiliatedByCommonDeals: false,
    affiliatedCustom: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_CLIENT_AFFILIATION_FILTERS:
      const { affiliationFilters } = initialState;
      return {
        ...state,
        affiliationFilters,
      };
    case SET_CLIENT_AFFILIATION_FILTERS:
      return {
        ...state,
        affiliationFilters: action.payload,
      };
    default:
      return state;
  }
}
